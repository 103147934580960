@import "./../../styles/colors.scss";

.sidebar {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 18rem;
  overflow-y: scroll;
  background-color: $primary;

  .logo {
    padding: 1rem 1.5rem;
    padding-top: 1.3rem;
    background-color: #247045;
    color: #fff;
  }
  .links-wrapper {
    .link-item {
      display: block;
      padding: 1rem 1.5rem;
      color: #fff !important;
      text-decoration: none;
      font-size: 0.99rem;
    }
    .link-item.active {
      background-color: #bfe9d1;
      color: #000 !important;
    }
  }
}
