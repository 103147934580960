.main {
  .links {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    .link {
      text-decoration: none;
      flex: 1;
      .link-container {
        flex: 1;
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        height: 5.9rem;
        border-radius: 0.7rem;
        box-shadow: 1px 3px 12px -2px rgba(28, 25, 25, 0.49);
        -webkit-box-shadow: 1px 3px 12px -2px rgba(28, 25, 25, 0.49);
        -moz-box-shadow: 1px 3px 12px -2px rgba(28, 25, 25, 0.49);
        p {
          padding: 0.8rem 1rem;
          color: #3f424a;
          font-size: 0.95rem;
        }

        .icon {
          font-size: 1.5rem;
          padding: 0.4rem 0.9rem;
          border-left: 2px solid #f8f9f8;
          color: #3f424a;
        }
      }
    }
  }
}
