.ant-select-selector {
  height: 45px !important;
  align-items: center;
  vertical-align: bottom !important;
}

button:disabled {
  background-color: #247045;
  color: white !important;
}
