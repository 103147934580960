@import "./colors.scss";

button {
  background-color: $primary;
  padding: 0.6rem 2.5rem;
  border: none;
  outline: none;
  border-radius: 0.3rem;
  color: #fff;
  &:disabled {
    background-color: $primary-dark;
  }
}

.home-wrapper {
  position: relative;
  background-color: #f3f4f7;
  .home {
    margin-top: 10vh;
    margin-left: 18rem;
    padding: 0 1.5rem;
    min-height: 85vh;
    @media (max-width: 900px) {
      margin-left: 0;
    }
  }
}
