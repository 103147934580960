@import "./colors.scss";

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .heading-create {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 0.8;
    .link {
      display: block;
      background-color: $primary;
      padding: 0.6rem 1.5rem 0.7rem;
      border-radius: 0.4rem;
      color: #fff;
      text-decoration: none;
    }
    input {
      flex: 1;
      padding: 0.65rem;
    }
  }
}
